import { api } from '../../config/base-api';
import {
  PaySubscriptionRenewParams,
  PaySubscriptionRenewResponse,
} from './types';

export async function paySubscriptionRenew(
  {
    subscriptionId,
    business,
    captchaToken,
    document,
    numberInstallments,
    paymentMethod,
    planId,
    productCode,
    seller,
    source,
    sessionId,
    metadata,
  }: PaySubscriptionRenewParams,
  isServer?: boolean
) {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/subscription/${subscriptionId}/renew`
    : `/api/v1/subscription/${subscriptionId}/renew`;

  const res = await api.post<PaySubscriptionRenewResponse>(requestUrl, {
    business,
    captchaToken,
    document,
    numberInstallments,
    paymentMethod,
    planId,
    productCode,
    seller,
    source,
    sessionId,
    metadata,
  });

  return res.data;
}
