import { api } from '../../config/base-api';
import {
  PaySubscriptionRenewV2Params,
  PaySubscriptionRenewV2Response,
} from './types';

export async function paySubscriptionRenewV2(
  {
    subscriptionId,
    seller,
    source,
    planId,
    captchaToken,
    amount,
    sessionId,
    document,
    business,
    productCode,
    type,
    paymentMethods,
    metadata,
  }: PaySubscriptionRenewV2Params,
  isServer?: boolean
) {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v2/subscription/${subscriptionId}/renew`
    : `/api/v2/subscription/${subscriptionId}/renew`;

  const res = await api.post<PaySubscriptionRenewV2Response>(requestUrl, {
    seller,
    source,
    planId,
    captchaToken,
    amount,
    sessionId,
    document,
    business,
    productCode,
    type,
    paymentMethods,
    metadata,
  });

  return res.data;
}
