import { api } from '../../config/base-api';
import {
  GetSubscriptionCustomSaleParams,
  GetSubscriptionCustomSaleResponse,
} from './types';

export async function getSubscriptionCustomSale(
  { subscriptionId }: GetSubscriptionCustomSaleParams,
  isServer?: boolean
): Promise<GetSubscriptionCustomSaleResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v2/subscription/${subscriptionId}/custom-sale`
    : `/api/v2/subscription/${subscriptionId}/custom-sale`;

  const res = await api.get<GetSubscriptionCustomSaleResponse>(requestUrl, {});

  return res.data;
}
